"use client"

import { ChevronDown } from "lucide-react"
import { usePathname } from "next/navigation"
import { useRef, useState } from "react"

export function CollapsibleFooter({ children }: { children: React.ReactNode }) {
    const path = usePathname()
    const [isOpen, setIsOpen] = useState(false)
    const contentRef = useRef<HTMLDivElement>(null)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
        if (!isOpen) {
            // Ensure we give time for the content to render before scrolling
            requestAnimationFrame(() => {
                contentRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                })
            })
        }
    }

    // Only render the collapsible footer on the dashboard pages:
    if (!path.startsWith("/dashboard") && !path.startsWith("/the-interview-people/assistant")) {
        return children
    }

    return (
        <div className="w-full text-white px-4">
            <button
                type="button"
                className="flex items-center justify-between w-full py-3 border-b border-white/20"
                aria-expanded={isOpen}
                onClick={toggleOpen}
            >
                <span className="flex-grow text-left">TCE</span>
                <ChevronDown
                    className={`transform transition-transform ${isOpen ? "rotate-180" : "rotate-0"}`}
                />
            </button>
            {isOpen && (
                <div ref={contentRef} className="transition-opacity duration-500 ease-in">
                    {children}
                </div>
            )}
        </div>
    )
}
