"use client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Suspense, useState } from "react"
import { ClientSidePostHogProvider } from "../components/posthog/providers"
import PostHogPageView from "@/components/posthog/pageview"
import { Analytics as VercelAnalytics } from "@vercel/analytics/react"
import { env, isProduction } from "@/env"
import { Toaster } from "@/components/ui/sonner"
import { BentoAnalytics } from "@bentonow/bento-nextjs-sdk/analytics"
import { useSession } from "next-auth/react"

/**
 *
 * These are only the providers for the (public) and (user) pages.
 *
 * The (ops) pages have their own providers.
 *
 **/

function BentoAnalyticsWrapper() {
    const user = useSession().data?.user

    return <BentoAnalytics siteUuid={env.NEXT_PUBLIC_BENTO_SITE_KEY} userEmail={user?.email ?? ""} />
}

export function Providers({ children }: { children: React.ReactNode }) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        staleTime: 60 * 1000 // 1 minute
                    }
                }
            })
    )

    return (
        <QueryClientProvider client={queryClient}>
            {isProduction ? (
                <>
                    <ClientSidePostHogProvider>
                        {children}
                        <Suspense fallback={null}>
                            <PostHogPageView />
                        </Suspense>
                    </ClientSidePostHogProvider>
                    <VercelAnalytics />
                    <BentoAnalyticsWrapper />
                </>
            ) : (
                children
            )}
            <Toaster richColors expand={true} theme="light" duration={8000} closeButton offset={15} />
        </QueryClientProvider>
    )
}
