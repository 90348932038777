"use client"

import { Popover, PopoverButton, PopoverGroup, PopoverPanel, Transition } from "@headlessui/react"
import { Menu, Bookmark, MousePointer, LifeBuoy, X, ChevronDown } from "lucide-react"
import Link from "next/link"
import { Fragment } from "react"
import AvatarDropdownMenu from "../common/avatar"
import TCELogo from "../icons/tce-logo"
import { cn } from "@/lib/utils"

const support = [
    {
        name: "Help Center",
        description: "Get all of your questions answered or contact support.",
        href: "/support",
        icon: LifeBuoy
    },
    {
        name: "Selling your content on TCE",
        description: "Sell your content to a global audience.",
        href: "/support/sellers",
        icon: MousePointer
    },
    {
        name: "Support Articles",
        description: "Guides and tutorials on how to use the TCE platform.",
        href: "/support/articles",
        icon: Bookmark
    }
]

function NavLink({ to, children, style }: { to: string; children: React.ReactNode; style?: string }) {
    const linkStyle =
        style ||
        "whitespace-nowrap text-base font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 transition-colors"
    return (
        <Link href={to} className={linkStyle}>
            {children}
        </Link>
    )
}

function MobileNavLink({ to, children, style }: { to: string; children: React.ReactNode; style?: string }) {
    const linkStyle =
        style ||
        "-m-3 flex items-center rounded-md p-3 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
    return (
        <PopoverButton as={Link} href={to} className={linkStyle}>
            {children}
        </PopoverButton>
    )
}

function MobileNav() {
    return (
        <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
        >
            <PopoverPanel
                focus
                className="absolute inset-x-0 top-0 z-[100] origin-top-right transform p-2 transition md:hidden"
            >
                <div className="divide-y-2 divide-gray-50 dark:divide-gray-800 rounded-lg bg-white dark:bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="px-5 pb-6 pt-5">
                        <div className="flex items-center justify-between">
                            <div>
                                <TCELogo />
                            </div>
                            <div className="-mr-2">
                                <PopoverButton className="inline-flex items-center justify-center rounded-md bg-white dark:bg-gray-900 p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-tce-500">
                                    <span className="sr-only">Close menu</span>
                                    <X className="h-6 w-6" aria-hidden="true" />
                                </PopoverButton>
                            </div>
                        </div>
                        <div className="mt-6">
                            <nav className="grid gap-y-8">
                                <MobileNavLink to="/">
                                    <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-100">
                                        Home
                                    </span>
                                </MobileNavLink>

                                <MobileNavLink to="/sources">
                                    <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-100">
                                        Content
                                    </span>
                                </MobileNavLink>
                            </nav>
                        </div>
                    </div>
                    <div className="space-y-6 bg-gray-50 dark:bg-gray-800 px-5 py-6">
                        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                            <PopoverButton
                                as={Link}
                                href="/contact"
                                className="text-base font-medium text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100 transition-colors"
                            >
                                Contact
                            </PopoverButton>
                            <PopoverButton
                                as={Link}
                                href="/about"
                                className="text-base font-medium text-gray-900 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-300"
                            >
                                About
                            </PopoverButton>
                            {support.map((item) => (
                                <PopoverButton
                                    key={item.name}
                                    as={Link}
                                    href={item.href}
                                    className="text-base font-medium text-gray-900 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-300"
                                >
                                    {item.name}
                                </PopoverButton>
                            ))}
                        </div>
                        <div>
                            <p className="mt-6 text-center text-base font-medium text-gray-500">
                                <PopoverButton
                                    as={Link}
                                    href="/login"
                                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-tce-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-tce-700"
                                >
                                    Sign In
                                </PopoverButton>
                            </p>
                        </div>
                    </div>
                </div>
            </PopoverPanel>
        </Transition>
    )
}

function NavBarLeft() {
    return (
        <>
            <div className="flex justify-start lg:w-0 lg:flex-1">
                <TCELogo />
            </div>
            <div className="-my-2 -mr-2 md:hidden">
                <PopoverButton className="inline-flex items-center justify-center rounded-md bg-white dark:bg-gray-900 p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-tce-500">
                    <span className="sr-only">Open menu</span>
                    <Menu className="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
            </div>
        </>
    )
}

function SupportMenu() {
    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <PopoverButton
                        className={cn(
                            open ? "text-gray-900 dark:text-gray-100" : "text-gray-500 dark:text-gray-400",
                            "group inline-flex items-center rounded-md  dark:bg-gray-900 text-base font-medium hover:text-gray-900 dark:hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-tce-500 focus:ring-offset-2"
                        )}
                    >
                        <span>Support</span>
                        <ChevronDown
                            className={cn(
                                open ? "text-gray-600" : "text-gray-400",
                                "ml-2 h-5 w-5 group-hover:text-gray-500"
                            )}
                            aria-hidden="true"
                        />
                    </PopoverButton>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <PopoverPanel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                                <div className="relative grid gap-6 bg-white dark:bg-gray-900 px-5 py-6 sm:gap-8 sm:p-8">
                                    {support.map((item) => (
                                        <PopoverButton
                                            key={item.name}
                                            as={Link}
                                            href={item.href}
                                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
                                        >
                                            <item.icon
                                                className="h-6 w-6 flex-shrink-0 text-tce-600"
                                                aria-hidden="true"
                                            />
                                            <div className="ml-4">
                                                <p className="text-base font-medium text-gray-900 dark:text-gray-100">
                                                    {item.name}
                                                </p>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    {item.description}
                                                </p>
                                            </div>
                                        </PopoverButton>
                                    ))}
                                </div>
                            </div>
                        </PopoverPanel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export function NavBar() {
    return (
        <Popover>
            <div className="bg-white dark:bg-gray-900">
                <nav
                    aria-label="main-navigation"
                    className="flex items-center max-w-7xl mx-auto justify-between px-8 py-6 md:justify-start md:space-x-10 bg-white dark:bg-gray-900"
                    style={{ height: `${100}px` }}
                >
                    {/* LEFT */}
                    <NavBarLeft />
                    {/* MIDDLE */}
                    <PopoverGroup as="nav" className="hidden space-x-10 md:flex">
                        <NavLink to="/buyers">Licensing</NavLink>
                        <NavLink to="/sources">Explore</NavLink>
                        <NavLink to="/cases">Cases</NavLink>
                        <NavLink to="/about">About</NavLink>
                        <NavLink to="/blog">Blog</NavLink>

                        {/* SUPPORT MENU */}
                        <SupportMenu />
                    </PopoverGroup>
                    {/* RIGHT */}
                    {/* <div className="items-center justify-end md:flex md:flex-1 lg:w-0 bg-blue-300"> */}
                    <AvatarDropdownMenu />
                    {/* </div> */}
                </nav>
            </div>
            <MobileNav />
        </Popover>
    )
}
