"use client"

import { usePathname, useSearchParams } from "next/navigation"
import { useEffect } from "react"
import { usePostHog } from "posthog-js/react"
import { useSession } from "next-auth/react"

export default function PostHogPageView(): null {
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const posthog = usePostHog()
    const session = useSession()
    const user = session.data?.user

    useEffect(() => {
        if (pathname && posthog) {
            let url = globalThis.window.origin + pathname
            if (searchParams.toString()) {
                url = url + `?${searchParams.toString()}`
            }

            posthog.capture("$pageview", {
                $current_url: url
            })
        }
    }, [pathname, searchParams, posthog])

    useEffect(() => {
        if (user && user.id && !posthog._isIdentified()) {
            posthog.identify(user.email ?? user.id, {
                email: user.email,
                name: user.name
            })
        }

        // Reset the posthog object if the user is not logged in:
        if (!user && posthog._isIdentified()) {
            posthog.reset()
        }
    }, [posthog, user])

    return null
}
