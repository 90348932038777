"use client"

import { env, isDevelopment } from "@/env"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { useEffect, type ReactNode } from "react"

export function ClientSidePostHogProvider({ children }: { children: ReactNode }) {
    useEffect(() => {
        posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
            api_host: "/ingest",
            ui_host: "https://eu.i.posthog.com",
            capture_pageview: false,
            person_profiles: "identified_only",
            capture_pageleave: true,
            enable_recording_console_log: true
        })
    }, [])

    if (isDevelopment) {
        return <>{children}</>
    }

    return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
